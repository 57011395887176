<template>
	<div class="content">
	    <div style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
	      <!-- <img style="width: 30px;height: 30px;" src="../static/icon06.png"/> -->
		  <!-- <span style="color: #7C3BED;" @click="back()">返回</span> -->
		  <img @click="back()" style="width: 30px;height: 30px;" src="../static/icon11.png"/>
	      <div v-if="userAddress" style="width: 200px;height: 40px;border-radius: 10px;text-align: center;line-height: 40px;background: #7C3BED33;color: white;">
	        <span>{{userAddress.substring(0,10)+'...'+userAddress.substring(36)}}</span>
	      </div>
		  <div v-if="!userAddress" style="width: 180px;height: 35px;border-radius: 18px;text-align: center;line-height: 35px;background: linear-gradient(90deg, rgba(46, 139, 220, 0.80) 0%, rgba(14, 147, 219, 0.80) 51.5%, rgba(15, 139, 179, 0.80) 100%);color: #FFF;font-size: 12px;"
			@click="connectBSC">
		    <span>{{Language[selectLan].p1k1}}</span>
		  </div>
	    </div>
	    <view class="second">
	      <view class="second01">
	        <span class="second02" style="color: #FFF; font-size: 24px; font-weight: 600;">{{Language[selectLan].p3k1}}</span>
	      </view>
	      <view style="width: 90%;margin: 14px auto;display: block;padding: 8px 2%;font-size: 14px;line-height: 17px;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #94a3b8;margin-top:0">
			<view style="width: 90%;display: flex;flex-direction: row;margin:15px auto;justify-content: space-between;">
				<view style="display: flex;flex-direction: row;align-items: center;width: 50%;">
				  <img style="width: 24px;height: 24px;margin-right: 5px;" src="../static/icon13.png"/>
				  <view style="display: flex;flex-direction: column;align-items: flex-start;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k2}}</span>
					  <!-- <span style="font-size: 14px;color: #FFF;">{{(dataStat2[3]+dataStat2[4]).toFixed(2)}}</span>-->
					   <span style="font-size: 14px;color: #FFF;">{{21000000}}</span>
				  </view>
				</view>
				<view style="display: flex;flex-direction: row;align-items: center;width: 50%;">
				  <img style="width: 24px;height: 24px;margin-right: 5px;" src="../static/icon13.png"/>
				  <view style="display: flex;flex-direction: column;align-items: flex-start;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k3}}</span>
					  <span style="font-size: 14px;color: #FFF;">{{(dataStat2[4]).toFixed(2)}}</span>
				  </view>
				</view>
			</view>
			<view style="width: 100%;height: 1px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0.43) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);margin: 15px auto;display: block;"></view>
			<view style="width: 90%;display: flex;flex-direction: row;margin:15px auto;justify-content: space-between;">
				<view style="display: flex;flex-direction: row;align-items: center;width: 50%;">
				  <img style="width: 24px;height: 24px;margin-right: 5px;" src="../static/icon13.png"/>
				  <view style="display: flex;flex-direction: column;align-items: flex-start;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k4}}</span>
					  <span style="font-size: 14px;color: #FFF;">{{(getrefMap2[3]).toFixed(2)}}</span>
				  </view>
				</view>
				<view style="display: flex;flex-direction: row;align-items: center;width: 50%;">
				  <img style="width: 24px;height: 24px;margin-right: 5px;" src="../static/icon13.png"/>
				  <view style="display: flex;flex-direction: column;align-items: flex-start;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k5}}</span>
					 <!-- <span style="font-size: 14px;color: #FFF;">{{(dataStat2[3]).toFixed(2)}}</span> -->
					  <span style="font-size: 14px;color: #FFF;">{{(21000000-dataStat2[4]).toFixed(2)}}</span>
				  </view>
				</view>
			</view>
	      </view>

	       <!-- <view style="width: 90%;margin:10px auto;display: flex;flex-direction: column;align-items: center;font-size: 14px;font-weight: 500;line-height: 30px;">
	        <span style="color: #64748B;">总交易量</span>
	        <span style="color: #11181C;">{{(dataStat2[5]+dataStat2[6])}}</span>
	      </view> -->
		  <!-- <view style="width: 90%;margin:10px auto;display: flex;flex-direction: column;align-items: center;font-size: 14px;font-weight: 500;line-height: 30px;">
		    <span style="color: #64748B;">总交易量</span>
		    <span style="color: #11181C;">{{(dataStat2[5]+dataStat2[6])}}</span>
		  </view>
		  <view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
		    <view style="display: flex;flex-direction: column;align-items: center;">
		      <span style="font-size: 14px;margin-top: 5px;margin-bottom: 10px;color: #64748B;">协议交易</span>
		      <span style="font-size: 14px;font-weight: 500;color: #11181C;">{{dataStat2[5]}}</span>
		    </view>
		    <view style="display: flex;flex-direction: column;align-items: center;">
		      <span style="font-size: 14px;margin-top: 5px;margin-bottom: 10px;color: #64748B;">博饼交易</span>
		      <span style="font-size: 14px;font-weight: 500;color: #11181C;">{{dataStat2[6]}}</span>
		    </view>
		  </view> -->
	    </view>
		
		<view class="second">
		  <view class="second01">
		    <span class="second02" style="color: #FFF;font-weight: 600;font-size: 24px;">{{Language[selectLan].p3k6}}</span>
		  </view>
		  <view style="width: 90%;margin: 14px auto;display: block;padding: 8px 2%;font-size: 14px;line-height: 17px;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #94a3b8;margin-top:0">
			  <view style="width: 90%;display: flex;flex-direction: row;margin:15px auto;justify-content: space-between;">
			    <view style="display: flex;flex-direction: column;align-items: flex-start;width: 50%;">
			      <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k7}}</span>
			      <span style="font-size: 14px;color: #FFF;">{{dataStat2[7].toFixed(2)}}</span>
			    </view>
			    <view style="display: flex;flex-direction: column;width: 50%;align-items: flex-end;">
			      <span style="margin-right: 0px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k8}}BNB</span>
			      <span style="font-size: 14px;color: #FFF;">{{(dataStat2[7]/price).toFixed(6) }}</span>
			    </view>
			  </view>
			  <view style="width: 100%;height: 1px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0.43) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);margin: 15px auto;display: block;"></view>
			  <view style="width: 90%;display: flex;flex-direction: row;margin:15px auto;justify-content: space-between;">
			    <view style="display: flex;flex-direction: column;align-items: flex-start;width: 50%;">
			      <span style="margin-right: 15px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k9}}</span>
			      <span style="font-size: 14px;color: #FFF;">{{dataStat[1].toFixed(2)}}</span>
			    </view>
			    <view style="display: flex;flex-direction: column;width: 50%;align-items: flex-end;">
			      <span style="margin-right: 0px;font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k8}}BNB</span>
			      <span style="font-size: 14px;color: #FFF;">{{(dataStat[1]/price).toFixed(6) }}</span>
			    </view>
			  </view>
		  </view>
		  <!-- <view style="width: 90%;margin: 8px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;font-size: 14px;">
		    <view style="display: flex;flex-direction: row;align-items: center;">
		      <span style="margin-right: 15px;font-size: 14px;color: #64748B;">团队销毁总额</span>
		      <span style="font-size: 14px;color: #11181C;">{{dataStat[2].toFixed(2)}}</span>
		    </view>
		    <view style="display: flex;flex-direction: row;align-items: center;">
		      <span style="margin-right: 15px;font-size: 14px;color: #64748B;">价值</span>
		      <span style="font-size: 14px;color: #11181C;">{{(dataStat[2]/price).toFixed(8) }}BNB</span>
		    </view>
		  </view> -->
		  
		</view>
		
	    <view class="second">
	      <view class="second01">
	        <!-- <span class="second02" style="color: black;font-weight: 600; font-size: 18px;">个人收益(:{{getrefMap[5].toFixed(2)}})</span> -->
			<span class="second02" style="color: #FFF;font-weight: 600; font-size: 24px;">{{Language[selectLan].p3k10}}</span>
	      </view>
		  <view style="width: 90%;margin: 14px auto;display: block;padding: 8px 2%;font-size: 14px;line-height: 17px;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #94a3b8;margin-top:0">
			  <view style="width: 90%;margin: 8px auto;display: flex;flex-direction: column;align-items: center;font-size: 14px;">				  
				<view style="display: flex;flex-direction: row;align-items: flex-start;justify-content: space-between;width: 100%;">
				  <view style="width: 50%;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;">{{Language[selectLan].p3k11}}</span>
				  </view>
				  <view style="display: flex;flex-direction: column;align-items: flex-end;width: 50%;">
					  <span style="font-size: 14px;color: #64748B;margin-bottom: 5px;">{{tokenName}}</span>
					  <span style="font-size: 14px;color: #FFF;">{{burnAmountR}}</span>
				  </view>
				</view>
				<view style="width: 100%;height: 1px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0.43) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);margin: 15px auto;display: block;"></view>
			    <view style="display: flex;flex-direction: row;align-items: flex-start;justify-content: space-between;width: 100%;">
				  <view style="width: 50%;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;">{{Language[selectLan].p3k12}}</span>
				  </view>
			      <view style="display: flex;flex-direction: column;align-items: flex-end;width: 50%;">
					  <span style="font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k8}}BNB</span>
					  <span style="font-size: 14px;color: #FFF;">{{(getrefMap[5]-getrefMap[6]).toFixed(6)}}</span>
				  </view>
			    </view>
				<view style="width: 100%;height: 1px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0.43) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);margin: 15px auto;display: block;"></view>

				<view style="display: flex;flex-direction: row;align-items: flex-start;justify-content: space-between;width: 100%;">
				  <view style="width: 50%;">
					  <span style="margin-right: 15px;font-size: 14px;color: #64748B;">{{Language[selectLan].p3k13}}</span>
				  </view>
				  <view style="display: flex;flex-direction: column;align-items: flex-end;width: 50%;">
					  <span style="font-size: 14px;color: #64748B;margin-bottom: 5px;">{{Language[selectLan].p3k8}}BNB</span>
					  <span style="font-size: 14px;color: #FFF;">{{getrefMap[6].toFixed(6) }}</span>
				  </view>
				</view>
			  </view>
		  </view>		  
	    </view>
		
	    <view style="margin-bottom: 100px;" class="second">
	      <view style="width: 90%;margin: 15px auto;font-size: 24px;color: black;display: block;font-weight: 600;" v-if="false">
	        <span>布道津贴</span>
	      </view>
	      <view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;" v-if="false">
	        <span style="font-size: 16px;color: #7d7d7d;">白名单津贴</span>
	        <span style="font-size: 16px;color: #7d7d7d;">BNB:{{getrefMap2[0].toFixed(6)}}</span>
	        <!-- <view style="width: 100px;height: 35px;border-radius: 5px;background: #7C3BED33;color: #7C3BED;font-size: 16px;font-weight: 600;display: block;line-height: 35px;text-align: center;">
	          <span>领取</span>
	        </view> -->
	      </view>
	      <view style="width: 90%;margin: 15px auto;font-size: 24px;color: #FFF;display: block;font-weight: 600;">
	        <span>{{Language[selectLan].p3k14}}</span>
	      </view>
	      <view style="width: 90%;margin: 14px auto;display: flex;flex-direction: column;padding: 10px 0;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #687DA4;margin-top:0">
	        <view style="width: 90%;margin: 10px auto;text-align: center;position: relative;">
	          <span style="font-size: 20px;color: #68c9ff;">{{Language[selectLan].p3k15}}</span>
	          <!-- <img style="width: 25px;height: 25px;position: absolute;top: 0;right: 0;" src="../static/icon07.png"/> -->
	        </view>
	        <view style="width: 90%;margin: 0 auto;text-align: center;font-size: 34px;font-weight: 600;color: #68C9FF;">
	          <span>{{dataStat[7].toFixed(2)}}</span>
	        </view>
	        <view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
	          <view>
	            <span style="font-size: 14px;">{{Language[selectLan].p3k16}}:</span>
	            <span style="font-size: 14px;color: #FFF;">
					<span v-if="getrefMap[1] >= param[7]" style="color: #68AFFF;">{{getrefMap[1]}}</span>
					<span v-if="getrefMap[1] < param[7]" style="color: #FFF;">{{getrefMap[1]}}</span>
					<span style="color: #FFF;">/{{param[7]}}</span>
				</span>
	          </view>
			  <view v-if="false" style="width: 1px;height: 20px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0.19) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);"></view>
	          <view style="font-size: 14px;">
	            <span>{{Language[selectLan].p3k17}}:</span>
	            <span v-if="getrefMap[2] >= param[8]" style="color:#68AFFF;">{{getrefMap[2]}}</span>
				<span v-if="getrefMap[2] < param[8]" style="color: #FFF;">{{getrefMap[2]}}</span>
	            <span style="color: #FFF;">/{{param[8]}}</span>
	          </view>
	        </view>
			
			<view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
			  <view>
			    <span style="font-size: 14px;">{{Language[selectLan].p3k18}}:</span>
			    <span style="font-size: 14px;color: #FFF;">
					<span style="color: #FFF;">{{getrefMap2[1]}}</span>
				</span>
			  </view>
			  <view v-if="false" style="width: 1px;height: 20px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0.19) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);"></view>
			  <view>
			    <span style="font-size: 14px;">{{Language[selectLan].p3k31}}:</span>
			    <span style="font-size: 14px;color: #FFF;">
					<span style="color: #FFF;">{{nodeCount}}</span>
				</span>
			  </view>
			</view>
			<view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
			  <view>
			    <span style="font-size: 14px;">{{Language[selectLan].p3k30}}:</span>
			    <span style="font-size: 14px;color: #FFF;">
					<span style="color: #FFF;">{{refBurnAmount}}</span>
				</span>
			  </view>
			  <view v-if="false" style="width: 1px;height: 20px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0.19) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);"></view>
			  <view>
			    <span style="font-size: 14px;">{{Language[selectLan].p3k32}}:</span>
			    <span style="font-size: 14px;color: #FFF;">
					<span style="color: #FFF;">{{refBurnAmountS}}</span>
				</span>
			  </view>
			</view>
	        <!-- <view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;font-size: 14px;">
	          <span style="margin-right: 20px;">{{Language[selectLan].p3k18}}</span>
	          <span style="color: white;">{{getrefMap2[1]}}</span>
	        </view> -->
	        <!-- <view style="width: 70%;margin: 10px auto;height: 45px;border-radius: 10px;background: #7C3BED33;color: #7C3BED;font-size: 16px;font-weight: 600;display: block;line-height: 45px;text-align: center;">
	          <span>领取津贴</span>
	        </view> -->
	      </view>
		  
		  <view style="width: 90%;margin: 15px auto;font-size: 24px;color: #FFF;display: block;font-weight: 600;">
		    <span>{{Language[selectLan].p3k19}}</span>
		  </view>
		  <view style="width: 90%;margin: 14px auto;display: flex;flex-direction: column;padding: 10px 0;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #687DA4;margin-top:0">
		    <view style="width: 90%;margin: 10px auto;text-align: center;position: relative;">
		      <span style="font-size: 20px;font-weight: 600;color: #FFF;">{{Language[selectLan].p3k20}}</span>
		      <!-- <img style="width: 25px;height: 25px;position: absolute;top: 0;right: 0;" src="../static/icon07.png"/> -->
		    </view>
		    <view style="width: 90%;margin: 0 auto;text-align: center;font-size: 34px;font-weight: 600;color: #68C9FF;">
		      <span>{{totalWhiteDividAmount.toFixed(2)}}</span>
		    </view>
		    <view style="width: 90%;margin: 10px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
		      <view>
		        <span style="font-size: 14px;">{{Language[selectLan].p3k21}} </span>
		        <span style="font-size: 14px;color: #FFF;">
					<span>{{totalWhiteDividAmountS.toFixed(2)}}</span>
				</span>
		      </view>
		      <view style="width: 1px;height: 20px;background: linear-gradient(0deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0.19) 100%), linear-gradient(90deg, rgba(11, 47, 104, 0.00) 0%, rgba(13, 57, 126, 0.80) 21.36%, rgba(20, 84, 186, 0.80) 80.86%, rgba(22, 93, 206, 0.00) 100%);"></view>
		      <view style="font-size: 14px;">
		        <span>{{Language[selectLan].p3k22}} </span>
		        
		        <span style="color: #FFF;">{{(totalWhiteDividAmountS * (getrefMap[4]/(param[5]-param[6]))).toFixed(2)}}</span>
		      </view>
		    </view>

		  </view>
		  
	      <view style="width: 90%;margin: 15px auto;font-size: 24px;color: #FFF;display: block;font-weight: 600;">
	        <span>{{Language[selectLan].p3k23}}</span>
	      </view>
	      <view style="width: 90%;margin: 14px auto;display: flex;flex-direction: column;padding: 10px 0;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #687DA4;margin-top:0">
	        <view style="width: 90%;margin: 10px auto;text-align: center;position: relative;">
	          <span style="font-size: 20px;font-weight: 600;color: #FFF;">{{Language[selectLan].p3k24}}</span>
	          <!-- <img style="width: 25px;height: 25px;position: absolute;top: 0;right: 0;" src="../static/icon07.png"/> -->
	        </view>
	        <view style="width: 90%;margin: 10px auto;text-align: center;font-size: 20px;font-weight: 600;color: red;">
	          <span v-if="userAddress == dataStat3[0].toLowerCase()">Number 1</span>
			  <span v-if="userAddress == dataStat3[1].toLowerCase()">Number 2</span>
			  <span v-if="userAddress == dataStat3[2].toLowerCase()">Number 3</span>
			  <span v-if="(userAddress != dataStat3[0].toLowerCase())&&(userAddress != dataStat3[1].toLowerCase())&&(userAddress != dataStat3[2].toLowerCase())">{{Language[selectLan].p3k25}}</span>
	        </view>
	        <view style="width: 70%;margin: 10px auto;display: flex;flex-direction: row;justify-content: space-between;align-items: center;font-size: 16px;color: white;">
	          <span>{{Language[selectLan].p3k26}}</span>
	          <span>{{dataStat3[4].toFixed(2)}}</span>
	        </view>
			<view style="width: 70%;margin: 10px auto;display: flex;flex-direction: row;justify-content: space-between;align-items: center;font-size: 16px;">
			  <span style="color: #64748B">Number 1{{Language[selectLan].p3k27}}: {{topAmount1.toFixed(2)}},{{Language[selectLan].p3k28}}50%</span>
			</view>
			<view style="width: 70%;margin: 10px auto;display: flex;flex-direction: row;justify-content: space-between;align-items: center;font-size: 16px;">
			  <span style="color: #64748B">Number 2{{Language[selectLan].p3k27}}: {{topAmount2.toFixed(2)}},{{Language[selectLan].p3k28}}30%</span>
			</view>
			<view style="width: 70%;margin: 10px auto;display: flex;flex-direction: row;justify-content: space-between;align-items: center;font-size: 16px;">
			  <span style="color: #64748B">Number 3{{Language[selectLan].p3k27}}: {{topAmount3.toFixed(2)}},{{Language[selectLan].p3k28}}20%</span>
			</view>
	        <!-- <view style="width: 70%;margin: 10px auto;height: 45px;border-radius: 10px;background: #7C3BED33;color: #7C3BED;font-size: 16px;font-weight: 600;display: block;line-height: 45px;text-align: center;">
	          <span>领取奖励</span>
	        </view> -->
	      </view>
		  
		  <view style="width: 90%;margin: 15px auto;font-size: 24px;color: #FFF;display: block;font-weight: 600;">
		    <span>{{Language[selectLan].p3k29}}</span>
		  </view>
		  <view style="width: 90%;margin: 14px auto;display: flex;flex-direction: column;padding: 10px 0;border-radius: 12px;border: 1px solid #0B2F68;background: var(--Base-Black, #0E0C20);color: #687DA4;margin-top:0">
			<view @click="queryMyChild" style="width: 70%;margin: 10px auto;height: 45px;border-radius: 10px;background: linear-gradient(90deg, rgba(46, 139, 220, 0.80) 0%, rgba(14, 147, 219, 0.80) 51.5%, rgba(15, 139, 179, 0.80) 100%);color: #FFF;font-size: 16px;font-weight: 600;display: block;line-height: 45px;text-align: center;">
			  <span>Reload</span>
			</view> 

			<view v-for="(item,index) in myChildAddress" style="width: 70%;margin: 2px auto;display: flex;flex-direction: row;justify-content: space-between;align-items: center;font-size: 16px;">
			  <span  style="color: white;margin-right: 2px;">{{item.substring(0,11)+'.........'+item.substring(30)}}</span>
			</view>
		  </view>
	    </view>
		
	  </div>
</template>

<!-- lang="ts" -->
<script>
	import Web3 from 'web3';
	import MyContractABI from "../abi/main.json"; // 导入合约 ABI
	import { message } from 'ant-design-vue'
	import Language from "../lan/lan.json"; //
	
	export default {
		// computed: {
		//   author () {
		//     return process.env.VUE_APP_AUTHOR;
		//   },
		//   apiBaseUrl () {
		//     return process.env.VUE_APP_API_BASE_URL;
		//   }
		// }
		data() {
			return {
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				
				//要修改的参数
				contractAddress:"0x8e856cf66b912aceb4d95a3ed92a47efeb27eaa6",
				//networkId:97,
				networkId:56,
				//end
				userAddress: "",
				dataStat: [0,0,0,0,0,0,0,0,0,0,0],
				tokenName:'PMM',
				mintAmount:0,
				price:0,
				nodeCount:0,
				refBurnAmount:0,
				refBurnAmountS:0,
				totalWhiteDividAmount:0,
				totalWhiteDividAmountS:0,
				dataStat2: [0,0,0,0,0,0,0,0],
				dataStat3: ["0x0000000000000000000000000000000000000000","0x0000000000000000000000000000000000000000"
				,"0x0000000000000000000000000000000000000000",0,0,0],
				getrefMap:["0x0000000000000000000000000000000000000000",0,0,0,0,0,0],
				getrefMap2:[0,0,0,0],
				param: [0,0,0,"0x0000000000000000000000000000000000000000","0x0000000000000000000000000000000000000000",0,0,0,0,0],
				exchangeAmount: 0,
				data2: {},
				bnbBalance: 0,
				topAmount1:0,
				topAmount2:0,
				topAmount3:0,
				web3: {},
				myChildAddress:[],
				burnAmountR:0,
				Language:Language,
				selectLan:'en',
			}
		},
		created() {
			if(localStorage.getItem('language')){
				this.selectLan = localStorage.getItem('language');
			}
			this.connectBSC(true);
			let that = this;
			this.interval = setInterval(function(){
				that.loadData();
			},1000*20)
		},
		destroyed() {
			if(this.interval){
				clearInterval(this.interval);
			}
		},
		methods: {
			async queryMyChild(){
				this.myChildAddress = [];
				for(let i =0;i<this.getrefMap[1];i++){
					console.log('1111111111111111');
					let childAddresses = await this.contract.methods.childAddresses(this.userAddress,i).call({});
					console.log(childAddresses,'childAddresses');
					this.myChildAddress.push(childAddresses);
				}
				message.success('query success!')
			},
			async queryNodeCount(){
				for(let i =0;i<10000;i++){
					await this.contract.methods.nodeAddresses(i).call({});
					this.nodeCount++;
				}
			},
			
			back(){
				this.$router.replace('/?notTo=1');
			},
			mint(){
				if(!this.mintAmount){
					message.error('must amount!')
					return;
				}
				if(this.mintAmount > this.param[2] || this.mintAmount < this.param[1]){
					message.error('amount need '+ this.param[1] +"-"+this.param[2])
					return;
				}
				
				if((parseFloat(this.getrefMap[4]) + parseFloat(this.mintAmount)) > this.param[2] ){
					message.error('mint max '+this.param[2])
					return;
				}
				
				const value = this.web3.utils.toWei(this.mintAmount, 'ether'); // 以太转账金额，这里以1以太为例
				 
				this.web3.eth.sendTransaction({
				  from: this.userAddress,
				  to: this.contractAddress,
				  value: value
				}, (error, transactionHash) => {
				  if (!error) {
					  message.error('error:'+transactionHash);
				    console.log(transactionHash); // 交易哈希
				  } else {
					message.error(error);
				    console.error(error);
				  }
				});
			},
			max() {
				this.mintAmount = this.param[2] - this.getrefMap[4]
			},
			async connectBSC(queryChildFlag) {
				// if (window.ethereum) {
				//     await window.ethereum.request({method: 'eth_requestAccounts'});
				//     window.web3 = new Web3(window.ethereum);
				//     return true;
				//   }
				let that = this;
				if (window.ethereum) {
					try {
						window.ethereum.on('accountsChanged', function (accounts) {
							// accounts 是一个数组，包含当前连接的所有账户
							console.log('change address:', accounts[0]);
							that.connectBSC();
							// 在这里处理账户变更后的逻辑
							// 例如更新界面上显示的账户信息等
						});
						console.log(window.ethereum, 'window.ethereum');
						// 请求用户授权连接到BSC
						const accounts = await window.ethereum.request({
							method: 'eth_requestAccounts'
						});
	
						console.log(accounts, 'accounts');
	
						// 创建web3实例
						this.web3 = new Web3(window.ethereum);
	
						// 获取用户地址
						this.userAddress = accounts[0].toLowerCase(); // 更新用户地址
						//this.userAddress = "";
	
						let bnbBalance = await this.web3.eth.getBalance(this.userAddress);
						this.bnbBalance = this.web3.utils.fromWei(bnbBalance, 'ether');
	
						// 加载合约
						console.log(MyContractABI,'MyContractABI');
						const networkId = await this.web3.eth.net.getId();
						if(networkId!=this.networkId){
							message.error('err network，please select BNB chain');
						}
						//const deployedNetwork = MyContractABI.networks[networkId];
						this.contract = new this.web3.eth.Contract(
							MyContractABI,
							// deployedNetwork && deployedNetwork.address
							this.contractAddress
						);
						
	
						this.connected = true;
						
						this.loadData(queryChildFlag);
					} catch (error) {
						console.error(error);
					}
				} else {
					alert("Please install MetaMask or a BSC-compatible wallet to use this DApp.");
				}
			},
			async loadData(queryChildFlag) {
				if (this.contract) {
					try {
						// 调用合约方法
						let dataStat = await this.contract.methods.getStatData().call({});
						let dataStat2 = await this.contract.methods.getStatData2().call({});
						let param = await this.contract.methods.getParam().call({});
						let getrefMap = await this.contract.methods.getrefMap(this.userAddress).call({});
						let totalWhiteDividAmount = await this.contract.methods.totalWhiteDividAmount().call({});
						let totalWhiteDividAmountS = await this.contract.methods.totalWhiteDividAmountS().call({});
						this.totalWhiteDividAmount =  parseFloat(this.web3.utils.fromWei(totalWhiteDividAmount, 'ether'));
						this.totalWhiteDividAmountS =  parseFloat(this.web3.utils.fromWei(totalWhiteDividAmountS, 'ether'));
						let burnAmountR = await this.contract.methods.burnAmountR(this.userAddress).call({});
						this.burnAmountR =  parseFloat(this.web3.utils.fromWei(burnAmountR, 'ether'));
						
						
						let refBurnAmount = await this.contract.methods.refBurnAmount(this.userAddress).call({});
						this.refBurnAmount =  parseFloat(this.web3.utils.fromWei(refBurnAmount, 'ether'));
						
						let refBurnAmountS = await this.contract.methods.refBurnAmountS(this.userAddress).call({});
						this.refBurnAmountS =  parseFloat(this.web3.utils.fromWei(refBurnAmountS, 'ether'));
						
						console.log(this.getrefMap, "this.getrefMap");
						
						param[1] = this.web3.utils.fromWei(param[1], 'ether');
						param[2] = this.web3.utils.fromWei(param[2], 'ether');
						param[3] = param[3].toLowerCase();
						param[4] = param[4].toLowerCase();
						param[5] = parseFloat(this.web3.utils.fromWei(param[5], 'ether'));
						param[6] = this.web3.utils.fromWei(param[6], 'ether');
						console.log(getrefMap[6],'getrefMap[6]');
						getrefMap[6] = parseFloat(this.web3.utils.fromWei(getrefMap[6], 'ether'));
						getrefMap[5] = parseFloat(this.web3.utils.fromWei(getrefMap[5], 'ether'));
						getrefMap[4] = parseFloat(this.web3.utils.fromWei(getrefMap[4], 'ether'));
						getrefMap[1] = parseInt(getrefMap[1]);
						getrefMap[2] = parseInt(getrefMap[2]);
						console.log(getrefMap[6],'getrefMap[6]');
						
						dataStat[1] = parseFloat(this.web3.utils.fromWei(dataStat[1], 'ether'));
						dataStat[6] = parseFloat(this.web3.utils.fromWei(dataStat[6], 'ether'));
						dataStat[7] = parseFloat(this.web3.utils.fromWei(dataStat[7], 'ether'));
						dataStat[2] = parseFloat(this.web3.utils.fromWei(dataStat[2], 'ether'));
						
						dataStat2[0] = parseFloat(this.web3.utils.fromWei(dataStat2[0], 'ether'));
						dataStat2[1] = parseFloat(this.web3.utils.fromWei(dataStat2[1], 'ether'));
						if(dataStat2[0] && dataStat2[1]){
							this.price = parseFloat(dataStat2[0])/ parseFloat(dataStat2[1]);
						}
						dataStat2[3] = parseFloat(this.web3.utils.fromWei(dataStat2[3], 'ether'));
						dataStat2[4] = parseFloat(this.web3.utils.fromWei(dataStat2[4], 'ether'));
						dataStat2[5] = parseFloat(this.web3.utils.fromWei(dataStat2[5], 'ether'));
						dataStat2[6] = parseFloat(this.web3.utils.fromWei(dataStat2[6], 'ether'));
						dataStat2[7] = parseFloat(this.web3.utils.fromWei(dataStat2[7], 'ether'));
						
						
						this.dataStat = dataStat
						this.dataStat2 = dataStat2
						this.param = param
						this.getrefMap = getrefMap
						console.log(this.dataStat,"dataStat:");
						console.log(this.dataStat2,"dataStat2:");
						console.log(this.param,"param:" );
						
						
						let getrefMap2 = await this.contract.methods.getrefMap2(this.userAddress).call({});
						getrefMap2[0] = parseFloat(this.web3.utils.fromWei(getrefMap2[0], 'ether'));
						getrefMap2[1] = parseFloat(this.web3.utils.fromWei(getrefMap2[1], 'ether'));
						getrefMap2[3] = parseFloat(this.web3.utils.fromWei(getrefMap2[3], 'ether'));
						
						let dataStat3 = await this.contract.methods.getStatData3().call({});
						console.log(this.dataStat3,"dataStat3:");
						dataStat3[3] = parseFloat(this.web3.utils.fromWei(dataStat3[3], 'ether'));
						dataStat3[4] = parseFloat(this.web3.utils.fromWei(dataStat3[4], 'ether'));
						
						let topAmount1 = await this.contract.methods.refBurnAmountS(dataStat3[0]).call({});
						let topAmount2 = await this.contract.methods.refBurnAmountS(dataStat3[1]).call({});
						let topAmount3 = await this.contract.methods.refBurnAmountS(dataStat3[2]).call({});
						this.topAmount1 = parseFloat(this.web3.utils.fromWei(topAmount1, 'ether'))
						this.topAmount2 = parseFloat(this.web3.utils.fromWei(topAmount2, 'ether'))
						this.topAmount3 = parseFloat(this.web3.utils.fromWei(topAmount3, 'ether'))
						
						let dividAmountMap = await this.contract.methods.dividAmountMap(this.userAddress).call({});
						console.log(dividAmountMap,'dividAmountMap');
						
						this.getrefMap2 = getrefMap2
						this.dataStat3 = dataStat3
						
						if(queryChildFlag){
							this.queryMyChild();
							this.queryNodeCount();
						}
					} catch (error) {
						console.error(error);
					}
				} else {
					console.error("Contract not initialized.");
				}
			}
		}
	}
</script>


<style>
body{
	background-image: url('../static/bg.png');
	background-size: 100% 100%;
}
	.content {
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	    }
	    .second{
	    width: 90%;
	    margin: 10px auto;
	    display: block;
	    border-radius: 20px;
	    border: 3px solid #4f82b4;
	    background: rgba(14, 12, 32, 0.50);
	    }
	    .second01{
	    width: 90%;margin: auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 10px 0;
	    }
	    .second02{
	    font-size: 16px;
	    }
	    .second03{
	    width: 40px;height: 40px;
	    }
	    .second04{
	    width: 90%;margin: 18px auto;border: 1px solid #d9d9d9;height: 180px;border-radius: 20px;display: block;
	    }
	    .second05{
	    width: 90%;margin: 25px auto;display: flex;flex-direction: row;align-items: center;justify-content: space-between;
	    }
	    .second09{
	      display: inline-block;width: 62px;height: 40px;border-radius: 12px;text-align: center;line-height: 40px;background-color: #7C3BED33;color: #7C3BED;font-size: 24px;margin-left: 20px;
	    }
</style>